
import { axiosInstance } from './interceptors';

export const login = async (request: any) => {
    return await axiosInstance.post(`auth/login/`, request);
};

export const forgotPassword = async (request: any) => {
    return await axiosInstance.post(`auth/forgotpassword/`, request);
};

export const otpVerification = async (request: any, userId: any) => {
    return await axiosInstance.post(`auth/verifyotp?id=${userId}`, request);
};

export const resetPassword = async (request: any, userId: any) => {
    return await axiosInstance.post(`auth/resetpassword?id=${userId}`, request);
};

export const changePassword = async (userId: string, request: any) => {
    console.log(request, userId)
    return await axiosInstance.post(`auth/resetpasswordbycurrentpassword/${userId}`, request);
};
