export const model = [
  
  {
    to: "/app",
    items: [
      {
        label: "users",
        icon: "pi pi-user-plus",
        to: "/app/user",
      },
    ],
  },
];
