import React, { useContext } from 'react';
import { MainLayout } from '../containers/layout';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from '../containers/views/login/login';
import ForgotPassword from '../containers/views/forgotpassword/forgotpassword';
import SetNewPaasword from '../containers/views/setnewpassword/setnewpassword';
import CheckMail from '../containers/views/checkmail/checkmail';
import { AuthLayout } from '../containers/layout';
import UserList from '../containers/views/user/userList';
import { LayoutProvider } from '../context';
import { AuthContext } from '../context';
import ProfileView from '../containers/views/profile/profileView';

const Index = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <LayoutProvider>
      <Routes>
        {isAuthenticated ? (
          <>
            <Route path='/app' element={<MainLayout />}>
              <Route index element={<UserList />} />
              <Route path='user' element={<UserList />} />
              <Route path='profile' element={<ProfileView />} />
              <Route path='*' element={<Navigate to='/app' replace />} />
            </Route>
          </>

        ) : null}
        <Route path='/login' element={<Login />} />
        <Route path='/auth' element={<AuthLayout />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path='/checkmail' element={<CheckMail />} />
        <Route path='/setnewpassword' element={<SetNewPaasword />} />
        <Route path='*' element={<Navigate to='/login' replace />} />
      </Routes>
    </LayoutProvider>
  );
};

export default Index;


