import axios from "axios";
import { toast } from "react-toastify";
import { getCookie, setCookie } from "../utils/cookieUtils";


export const axiosInstance = axios.create({

  baseURL: process.env.REACT_APP_API_BASE_URL,
}
);

axiosInstance.interceptors.request.use(
  function (config) {
    const user = getCookie('user');
    if (user) {
      const data = user
      config.headers = { 'Authorization': `Bearer ${data.tokens.accessToken}`, 'Accept': 'application/json' };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);


axiosInstance.interceptors.response.use(
  function (response) {
    const { message } = response.data;
    toast.success(message);
    return response.data;
  },
  async function (error) {
    const { code, message } = error.response.data;
    if (code === 500) {
      try {
        const userTocken = getCookie('user');
        if (userTocken) {
          const data = userTocken
          const refreshToken = data.tokens.refreshToken;


          if (!refreshToken) {
            // Handle case where refreshToken is not available
            throw new Error("Refresh token not found");
          }
          // const refreshResponse = await getRefreshToken({refreshToken});
          const refreshResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/refreshToken`, { refreshToken });
          const { accessToken, refreshToken: newRefreshToken } = refreshResponse.data.tokens;
          const user = getCookie('user');

          if (user) {
            const userData = user;
            userData.tokens.accessToken = accessToken;
            userData.tokens.refreshToken = newRefreshToken;
            setCookie('user', JSON.stringify(userData));
          }

          // Retry the original request with the new access token
          const originalRequest = error.config;
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axiosInstance(originalRequest);
        }
      } catch (refreshError) {
        // Handle refresh token error
        toast.error('Your session has expired, Please login again');
        return Promise.reject(error);
      }
    }

    toast.warning(message);
    return Promise.reject(error);
  }
);
