import { Dialog } from 'primereact/dialog';
import { useTranslation } from "react-i18next";
import { CancelButton } from '../styledcomponents';
import "./viewDetails.css"
const ViewDetailsModal = ({ setVisible, visible = false, details, tittle }: any) => {
    const [t, i18n] = useTranslation()


    return (
        <div className="card flex justify-content-center">
            <Dialog header={tittle} visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}  >
                <div id="invoice-content">
                    <div className="invoice-wrapper">
                        <div className="invoice-content">
                            <div className="invoice-footer grid grid-nogutter">
                                <div className="col-12">
                                    <div className="invoice-table-2 grid grid-nogutter">
                                        <div className="col-6">
                                            {Object.keys(details).map(key => (
                                                <div className="invoice-table-col header-col capitalize" key={key}>
                                                    <span>{t(key)}</span>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-6">
                                            {Object.keys(details).map(key => (
                                                <div className="invoice-table-col content-col" key={key}>
                                                    {key === 'Email' ? (
                                                        <span>{details[key]}</span>
                                                    ) : (
                                                        <span className='capitalize'>{details[key]}</span>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                            </div>                            
                        </div>
                    </div>
                </div>               
            </Dialog>
        </div>
    )
}
export default ViewDetailsModal;
