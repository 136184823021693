import React, { useState, useEffect } from "react";
import DataGrid from "../../../components/datagrid/DataGrid";
import { userSvc } from '../../../services';
import UserModal from "./userModal";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import { useSelector } from "react-redux";
import ViewDetailsModal from "../../../components/modal/viewDetailsModal";
import ActionButton from "../../../components/common/buttons/actionButton";
import SearchBar from "../../../components/common/searchBar/SearchBar";




const UserList: React.FC = () => {
  const [tableData, setTableData] = useState([]);
  const [totalRecords, settotalRecords] = useState(0);
  const [page, setPage] = useState('1');
  const [limit, setLimit] = useState('10');
  const [visible, setVisble] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [id, setId] = useState<string | undefined>();
  const [t] = useTranslation()
  const[values,setValues]=useState<any>('')
  const [viewDetails, setviewDetails] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState(''); //For serch bar
  const columns = [
    { field: 'employee_no', header: 'Sl No' },
    { field: 'first_name', header: 'first_name' },
    // { field: 'middle_name', header: 'middle_name' },
    { field: 'last_name', header: 'last_name' },
    { field: 'email', header: 'email' },
    { field: 'phone', header: 'phone' },
    {
      field: 'status', header: 'Status', type: 'status', status: [
        { key: 'active', value: 'success' },
        { key: 'Active', value: 'info' },
        { key: 'rejected', value: 'danger' },
      ]
    }
  ];


  const userData = () => {
    userSvc.list(String(limit), String(page)).then(res => {
      setTableData(res.data.employee);
      settotalRecords(res.data.recordcount);
    });
  };

  useEffect(() => {
    userData()
  }, [limit, page]);

  const handleStatusUpdate = (e: any, id: string,newStatus:any) => {
    // const newStatus = e.target.value;
    userSvc.statusUpdate(id, newStatus).then(res => {
      userData();
    });
  };

  const getPaginationProps = (p: string, l: string) => {
    setPage((Number(p) + 1).toString());
    setLimit(l);
  };
  const onCreateOrUpdate = (i: string) => {
    setId(i);
    setVisble(true);
  };

  const details = (id: string) => {
    userSvc.get(id).then(res => {
      setValues(res.data);
    });
    setviewDetails(true);
  }

  const userDetails: any = {
    "User Id": values.employee_no?values.employee_no:"N/A",
    "Name": values? `${values.first_name} ${values.middle_name} ${values.last_name} `: "N/A",
    "Email": values.email?values.email:"N/A",
    "Mobile":values.phone?values.phone:"N/A",
    "Agency":values.agency?values.agency:"N/A",
    "Gender":values.gender?values.gender:"N/A",
    "status": values.status?values.status:"N?A",
};
if (values.address) {
  values.address.forEach((address: any, index: number) => {
    userDetails[`Address Type ${index + 1}`] = address.address_type || "N/A";
    userDetails[`Country ${index + 1}`] = address.country || "N/A";
    userDetails[`State ${index + 1}`] = address.state || "N/A";
    userDetails[`City ${index + 1}`] = address.city || "N/A";
    userDetails[`District ${index + 1}`] = address.district || "N/A";
    userDetails[`Pin Code ${index + 1}`] = address.pin_code || "N/A";
  });
}





  const permissionList = useSelector((state: any) => state.permissions.permissions);

  const deleteRecord = (i: string) => {
    userSvc.Delete(i).then(res => {
      if (res) {
        toast.success('User deleted successfully');
        userData();
      } else {
        toast.error('Failed to delete user');
      }
    });
  }

  const deleteByIdList = (i: any) => {
    userSvc.multipleDelete(i).then(res => {
      if (res) {
        toast.success('Users deleted successfully');
        userData();
      } else {
        toast.error('Failed to delete Users');
      }
    });
  }
  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteDialog(false)} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => { deleteRecord(id ?? " "); setDeleteDialog(false) }} />
    </>
  );

    // Implement Search bar 
    const handleSearch = () => {
      userSvc.serchUser(searchQuery).then(res => {
        if (res) {
          setTableData(res.data.employee);
        } 
      });
  
    };
  
    const handleInputChange = (e: any) => {
      const value = e.target.value;
      setSearchQuery(value);
      if (value === '') {
        userData()
      }
    };
    const userSearch = () => {
  
      return (
        <React.Fragment>
          <SearchBar
            searchQuery={searchQuery}
            handleInputChange={handleInputChange}
            handleSearch={handleSearch}
          />
        </React.Fragment>
      );
    };


  const extraAction = ({ rowData }: any) => {
    const hasViewPermission = permissionList && permissionList.includes('pages.user.get');
    const hasUpdatePermission = permissionList && permissionList.includes('pages.user.update');
    const hasDeletePermission = permissionList && permissionList.includes('pages.user.delete');
    return <>
      {hasViewPermission &&  (<ActionButton label={t("View")+''} icon="pi pi-eye" className="p-button-secondary" onClick={() => { details(rowData._id) }} />)}
      {hasUpdatePermission && (<ActionButton label={t("Update")+''} icon="pi pi-pencil"  className="p-button-secondary" onClick={() => { onCreateOrUpdate(rowData._id); }} />)}
      {hasDeletePermission && (<ActionButton label={t("Delete")+''} icon="pi pi-trash"  className="p-button-secondary" onClick={() => { setDeleteDialog(true); setId(rowData._id) }} />)}
    </>;
  };

  return (
    <>
      <DataGrid tittle={t("users")} data={tableData} columns={columns} totalRecords={totalRecords} paginationProps={getPaginationProps} onCreateOrEdit={onCreateOrUpdate}
        deleteByIdList={deleteByIdList} extraAction={extraAction} handleStatusUpdate={handleStatusUpdate} SearchBar={userSearch}/>
      <UserModal id={id} setVisible={setVisble} visible={visible} updateTableData={userData} />
      <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal onHide={() => setDeleteDialog(false)} footer={deleteDialogFooter}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {!id ? <span>Are you sure you want to delete the selected items?</span> : <span>Are you sure you want to delete ?</span>}
        </div>
      </Dialog>
      {viewDetails && <ViewDetailsModal  setVisible={setviewDetails} visible={viewDetails} details={userDetails} tittle={userDetails.Name}/>}

    </>
  );
};

export default UserList;