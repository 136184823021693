import * as Yup from 'yup';

export const userSave = Yup.object().shape({
    email: Yup.string().required('Required').email('Invalid email'),
    phone: Yup.string().required("Required"),
    gender: Yup.string().required('Required'),
    enable_login: Yup.string().required('Required'),
    first_name: Yup.string().required('Required').max(50, "max limit 50").min(2, "Min limit 2"),
    middle_name: Yup.string().optional().max(50, "max limit 50").min(1, "Min limit 1"),
    last_name: Yup.string().max(50, "Max limit 50").min(1, "Min limit 1").required('Required'),
    address: Yup.array().of(
        Yup.object().shape({
            country: Yup.string().required('Required'),
            state: Yup.string().required('Required'),
            city: Yup.string().required('Required'),
            district: Yup.string().required('Required'),
            pin_code: Yup.string().required('Required').matches(/^[1-9][0-9]{5}$/, 'Invalid PIN code'),
            address_type: Yup.string().required('Required')
        })
    )
});